<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-12">
            <label class="align-middle">Requisition Details:</label>
            <div class="mt-1">
              <template :key="index" v-for="(data, index) in formData.requisition_details">
                Requisition No: <strong>{{ data.requisition_number }}</strong>, Requisition Date:
                <strong>{{ data.requisition_date }}</strong>,
                Requester Name: <strong>{{ data.requester_name }}</strong>
                <br>
              </template>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <label class="align-middle">Fund Requisition by</label>
            <v-select
                placeholder="Select Contact"
                v-model="formData.contact_profile_id"
                :options="contacts"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">FR No</label>
            <input type="text" class="form-control" :value="formData.fr_number" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">FR Date</label>
            <input type="date" class="form-control date-mask" v-model="formData.fr_date">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row mt-3 px-2">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>PR No</th>
            <th>PR Date</th>
            <th>Requester</th>
            <th>Project</th>
            <th>Department</th>
            <th class="text-end">Amount</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="itemsDetails.length">
            <tr v-for="(item, i) in itemsDetails" :key="i">
              <td>{{ item.requisition_number }}</td>
              <td>{{ item.requisition_master.date }}</td>
              <td>{{ item.requisition_master?.user?.name }}</td>
              <td>{{ item.requisition_master?.project?.name }}</td>
              <td>{{ item.requisition_master?.department?.name }}</td>
              <td class="text-end">{{ item.total_amount }}</td>
            </tr>
          </template>
          <template v-if="accountsDetails.length">
            <tr v-for="(item, i) in accountsDetails" :key="i">
              <td>{{ item.requisition_number }}</td>
              <td>{{ item.requisition_master.date }}</td>
              <td>{{ item.requisition_master?.user?.name }}</td>
              <td>{{ item.requisition_master?.project?.name }}</td>
              <td>{{ item.requisition_master?.department?.name }}</td>
              <td class="text-end">{{ item.total_amount }}</td>
            </tr>
          </template>
          <tr v-if="itemsDetails.length || accountsDetails.length" style=" background-color: #f3f2f7; ">
            <td colspan="5" class="text-end">
              <strong>Total</strong>
            </td>
            <td class="text-end">
                <strong>{{ formData.total_amount }}</strong>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-if="!itemsDetails.length && !accountsDetails.length" class="text-center mt-5">No data</p>
      </div>
    </div>

    <div class="row mt-3 px-2">
      <div class="col-12">
        <div class="mb-2">
          <label for="note" class="form-label fw-bold">Memo:</label>
          <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                    id="description"></textarea>
        </div>
      </div>
    </div>

    <div class="px-2 mt-3 mb-3">
      <div class="row mb-1 d-flex justify-content-end">
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="saveFR()"
              :disabled="disableSave"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <GlobalLoader/>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background {
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore()

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {
        fetchFundRequisitionNumber,
        createNewFundRequisition,
        fetchSingleFundRequisition,
        updateFundRequisition,
        fetchRequisitionGeneralData
      } = handleRequisitions();
const {fetchContactProfiles} = handleContact()

const companyId   = computed(() => {
  return route.params.companyId
})
let headerTitle   = ref('Create Fund Requisition from PR')
let loader        = ref(false)
let productLoader = ref(false)
let disableSave   = ref(false)
let products      = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let frEditData    = ref({})
let isFRCreate    = true
let frData        = {
  general: [],
}
let formData      = ref({
  contact_profile_id : null,
  fr_number : null,
  fr_date : null,
  requisition_details: [],
  total_amount : 0.00,
  description : '',
  item_details : [],
  account_details : [],
})
let contacts = ref([])
let contact_profile_id = ref(null)

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes   = fetchContactProfiles(companyQuery)

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data

      if (route.query.requisitionId && route.query.requisitionId !== '' && route.query.requisitionId !== undefined) {
        prepareUpdatePageData();
      } else {
        prepareCreatePageData();
      }
    })
  ])
      .then(() => {
        loader.value = false;
      })
      .catch((err) => {
        loader.value = false
      })
}

function prepareUpdatePageData(){
  isFRCreate    = false;
  const fetchFR = fetchSingleFundRequisition(route.query.requisitionId, getQuery());
  fetchFR.then(res2 => {
    frEditData.value = res2.data;
    setFormData();
  });
}

function prepareCreatePageData(){
  const selected_requisitions    = {
    selected_requisitions: route.query.selected_requisitions
  }
  // Generate FR number
  const frNumberRes = fetchFundRequisitionNumber(getQuery());
  frNumberRes.then(res => {
    formData.value.fr_number = res;
  });
  // Fetch Requisition Generals
  const fetchRequisitionGenerals = fetchRequisitionGeneralData(selected_requisitions, getQuery());
  fetchRequisitionGenerals.then(res => {
    if (res.status) {

      let prData = res.data;
      delete formData.value.item_details;
      delete formData.value.account_details;
      formData.value.total_amount        = 0;
      formData.value.item_details        = [];
      formData.value.account_details     = [];
      formData.value.requisition_details = [];

      prData.forEach((item) => {
        formData.value.requisition_details.push({
          requisition_number: item.requisition_number,
          requisition_date  : new Date(item.date).toISOString().split('T')[0],
          requester_name    : item.user.name,
        });

        if (item.general.length > 0){
          item.general.forEach((general) => {
            if (general.procurement_type === "spot_purchase"){
              formData.value.total_amount += parseInt(general.total_amount);
              formData.value.item_details.push(general);
            }
          });
        }

        if (item.account.length > 0){
          item.account.forEach((account) => {
            if (account.procurement_type === "spot_purchase"){
              formData.value.total_amount += parseInt(account.total_amount);
              formData.value.account_details.push(account);
            }
          });
        }
      })
      setFormData();
    }
  })
}

function setFormData() {
  if (isFRCreate) {
    formData.value.fr_date     = new Date().toISOString().split('T')[0];
  } else {
    headerTitle.value          = 'Update Fund Requisition';
    formData.value.contact_profile_id = frEditData.value.contact_profile_id;
    formData.value.fr_date = new Date(frEditData.value.fr_date).toISOString().split('T')[0];
    formData.value.fr_number = frEditData.value.fr_number;
    formData.value.total_amount = 0.00;
    formData.value.description = frEditData.value.description;
    let prData = frEditData.value.requisition_masters;

    delete formData.value.item_details;
    delete formData.value.account_details;
    formData.value.item_details        = [];
    formData.value.account_details     = [];
    formData.value.requisition_details = [];

    prData.forEach((item) => {
      formData.value.requisition_details.push({
        requisition_number: item.requisition_number,
        requisition_date  : new Date(item.date).toISOString().split('T')[0],
        requester_name    : item.user.name,
      });

      if (item.general.length > 0){
        item.general.forEach((general) => {
          if (general.procurement_type === "spot_purchase"){
            formData.value.total_amount += parseInt(general.total_amount);
            formData.value.item_details.push(general);
          }
        });
      }

      if (item.account.length > 0){
        item.account.forEach((account) => {
          if (account.procurement_type === "spot_purchase"){
            formData.value.total_amount += parseInt(account.total_amount);
            formData.value.account_details.push(account);
          }
        });
      }
    })
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
  delete route.query.requisitionId;
  router.push({name: `fund-requisition-list`, params: route.params, query: route.query});
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function saveFR() {
  if (isFRCreate) {
    handleFRSubmit();
  } else {
    handleUpdateFR();
  }
}

function handleFRSubmit() {
  frData.company_id  = companyId.value;
  frData.contact_profile_id = formData.value.contact_profile_id;
  frData.fr_number = formData.value.fr_number;
  frData.fr_date = formData.value.fr_date;
  frData.total_amount = formData.value.total_amount;
  frData.fr_type = "from_pr";
  frData.description = formData.value.description;

  frData.general = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      console.log(item)
      let data = {};
      data.ref_number = item.requisition_number;
      data.requisition_id = item.requisition_master_id;
      data.ref_date = item.requisition_master.date;
      data.party_name = item.requisition_master?.user?.name;
      data.total_amount  = getTotalAmount(item, item.amount, item.vat_amount);

      frData.general.push(data);
    })
  }
  if (formData.value.account_details.length > 0) {
    formData.value.account_details.forEach((item) => {
      let data           = {};
      data.ref_number = item.requisition_number;
      data.requisition_id = item.requisition_master_id;
      data.ref_date = item.requisition_master.date;
      data.party_name = item.requisition_master?.user?.name;
      data.total_amount  = getTotalAmount(item, item.amount, item.vat_amount);

      frData.general.push(data);
    })
  }
  frData.general = JSON.stringify(frData.general);

  createFR();
}

function handleUpdateFR() {
  frData.company_id  = companyId.value;
  frData.contact_profile_id = formData.value.contact_profile_id;
  frData.fr_number = formData.value.fr_number;
  frData.fr_date = formData.value.fr_date;
  frData.description = formData.value.description;

  updateFR();
}

function createFR(redirect = true) {
  createNewFundRequisition(frData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updateFR() {
  updateFundRequisition(frData, route.query.requisitionId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message);
          onPageReload();
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false;
        console.log(err)
      })
}

function navigateToListPage() {
  delete route.query.frId;
  delete route.query.selected_requisitions;
  router.push({name: `fund-requisition-list`, params: route.params, query: route.query});
}

function getTotalAmount(data, amount, vat_amount) {
  if (data.vat == 0) {
    return parseInt(amount)
  }
  if (data.is_product == "1") {
    return parseInt(amount) + parseInt(vat_amount)
  }

  return parseInt(amount) + parseInt(vat_amount)
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})

onMounted(async () => {
  onPageReload();
})
</script>
